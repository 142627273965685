.main-page {
  display: grid;
  grid-template-rows: 25% 75%;
  grid-gap: 24px;
  justify-items: center;
  /* overflow: hidden; */
  width: 100vw;
  height: 100vh;
}

.main-page__head {
  align-self: center;
  display: flex;
  align-items: center;
  width: 1184px;
}

.main-page__title {
  padding-right: 100px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  color: #000000;
}

.main-page__head img {
  width: 230px;
  margin-right: 60px;
}

.main-page__main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  /* align-items: baseline; */
  align-self: center;
}

.main-page__block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23em;
  height: 360px;
}

.main-page__block-img {
  position: absolute;
  top: -147px;
  left: 58px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 252px;
  height: 200px;
}

.main-page__block:first-child > .main-page__card > .main-page__block-img {
  padding-top: 8px;
}

.main-page__block-img img {
  width: auto;
  height: 100%;
}

.main-page__card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23em;
  height: 320px;
  margin-top: auto;
  padding: 24px 14px;
  background: #f2f5f5;
  border-radius: 20px;
}

.main-page__card-text {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 6px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  opacity: 0.8;
}

.main-page__card-numbers {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  color: #000;
}

.fraction {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.fraction span {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.fraction__line {
  width: 100%;
  height: 1px;
  background: #8b939e;
}

.main-page__card-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 10px);
  height: 54px;
  border: none;
  outline: none !important;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #545967;
  transition: all 0.2s ease-out;
}

.main-page__card-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #545967;
  text-decoration: none;
}

.main-page__card-button:hover {
  box-shadow: 0px 8px 16px rgba(122, 134, 143, 0.08);
}

.main-page__card-button:hover a {
  color: #167ffb;
  transition: color 0.2s ease-out;
}

.main-page__card-button:disabled {
  cursor: default;
  color: #c7ced9;
}

.main-page__card-button:disabled:hover {
  box-shadow: none;
}

.main-page__block:nth-child(2) > .main-page__card > .main-page__block-img {
  padding-top: 38px;
}

/** узкий экран  */
@media all and (max-height: 660px) {
  .main-page {
    grid-template-rows: 25% 65%;
  }
  .main-page__card {
    height: 280px;
  }
  .main-page__card-numbers {
    margin-bottom: 20px;
  }
  .main-page__block-img {
    top: -110px;
    height: 150px;
  }
  .main-page__block:nth-child(2) > .main-page__card > .main-page__block-img {
    padding-top: 38px;
  }
}

/** tablet landscape */
@media all and (max-width: 1230px) {
  .main-page {
    padding: 48px 28px 80px 28px;
  }
  .main-page__head {
    width: 100%;
  }
  .main-page__main {
    grid-gap: 24px;
    align-items: center;
  }
  .main-page__title {
    width: 100%;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    padding-right: 80px;
  }
  .main-page__block,
  .main-page__card {
    width: auto;
  }
  .main-page__card {
    height: 266px;
    padding: 50px 16px 16px 16px;
  }
  .main-page__block-img {
    display: flex;
    align-items: center;
    top: -88px;
    left: 0;
    width: 100%;
    height: 120px;
  }
  .main-page__block:nth-child(2) > .main-page__card > .main-page__block-img {
    padding-top: 38px;
  }
  .main-page__block-img img {
    width: 140px;
  }
  .main-page__card-text {
    margin: 0 0 auto 0;
    font-size: 15px;
    line-height: 20px;
    padding: 0;
  }
  .main-page__card-numbers {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 49px;
  }
}

.language {
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 48px;
  z-index: 100;
  user-select: none;
  outline: none;
  display: flex;
}

.language_inner_page {
  right: 110px;
  top: 26px;
}

@media all and (max-width: 480px) {
  .language {
    top: 20px;
  }
}

@media all and (min-width: 540px) and (max-width: 896px) {
}

.language-dropdown-option {
  padding: 4px 6px;
  border-radius: 8px;
  list-style: none;
}

.language-dropdown-option-active {
  box-shadow: 0 8px 16px rgb(122 134 143 / 8%);
  color: #167ffb;
  background: #f2f5f5;
  cursor: default;
}

.language-dropdown-option:hover {
  color: #167ffb;
}

.language-dropdown {
  position: absolute;
  top: 25px;
  padding: 4px 8px;
  background: white;
  margin-left: -4px;
}

/** tablet portrait */
@media all and (max-width: 896px) {
  .main-page {
    grid-template-rows: none;
    grid-gap: 0;
    display: flex;
    flex-direction: column;
    padding: 48px 16px;
    overflow: auto;
    position: relative;
  }
  .main-page__head {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 82px;
  }
  .main-page__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
  }
  .main-page__main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .main-page__block {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .main-page__block:nth-child(1) {
    margin-bottom: 40px;
  }
  .main-page__block:nth-child(2) {
    margin-bottom: 70px;
  }
  .main-page__card {
    width: 100%;
    height: auto;
    padding: 60px 8px 16px 8px;
  }
  .main-page__block:nth-child(2) > .main-page__card > .main-page__block-img {
    padding-top: 0;
  }
  .main-page__block-img {
    display: flex;
    align-items: center;
    top: -86px;
    left: 0;
    width: 100%;
    height: 120px;
  }
  .main-page__block:nth-child(2) .main-page__block-img {
    top: -80px;
  }
  .main-page__card-text {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .main-page__block:nth-child(3) .main-page__card-text {
    max-width: 480px;
  }
  .main-page__card-numbers {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 49px;
  }
  .main-page__card-numbers .fraction span {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
  }
  .main-page__card-numbers .fraction span sub {
    font-size: 10px;
  }
  .main-page__card-button {
    max-width: 300px;
  }
}

/** phone */
@media all and (max-width: 480px) {
  .main-page {
    padding: 24px 16px 16px 16px;
  }
  .main-page__title {
    font-size: 20px;
    line-height: 24px;
    padding: 0;
  }
  .main-page__block {
    width: 100%;
  }
  .main-page__card {
    padding: 50px 16px 16px 16px;
  }
}

/*! tablet */
@media all and (min-width: 540px) and (max-width: 896px) {
  .main-page__head {
    flex-direction: row;
  }
  .main-page__title {
    font-size: 32px;
    line-height: 39px;
    text-align: left;
    padding-right: 100px;
  }
  .main-page__block:nth-child(2) > .main-page__card > .main-page__block-img {
    padding-top: 0;
  }
  .language_inner_page {
    top: 8px;
  }
}
