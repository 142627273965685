@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
@import "./adaptive.css";

/* common */
:root {
  --main-color: #167ffb;
  --aside-bg-color: #e7edef;
  --special-font-size: 17px;
}

/* tablet */
@media all and (min-width: 540px) and (max-width: 896px) {
  :root {
    --special-font-size: 12px;
  }
}

/* phone */
@media all and (max-width: 540px) {
  :root {
    --special-font-size: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
sub {
  font-size: 12px;
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@supports (-webkit-touch-callout: none) {
  html,
  body {
    /* height: -webkit-fill-available; */
  }
}

input[type="checkbox"] {
  display: none;
}

label {
  cursor: pointer;
}

.icons {
  display: none;
}

.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 93px;
  color: #000000;
}

.h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.text {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  max-width: 190px;
}

.page {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 500px;
  width: 100%;
  padding-right: 32px;
}

.info {
  display: none;
}

.logo {
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 0;
  width: 85px;
  height: 47px;
}

.logo--main {
  position: static;
  width: 166px;
  height: 91px;
  margin: 0 48px 0 0;
}

.loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
}

.info {
  position: absolute;
  z-index: 90;
  top: 29px;
  left: 200px;
  display: block;
  padding-right: 90px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #8b939e;
}

@media screen and (max-width: 2560px) {
  .page {
    grid-template-columns: 1fr 355px;
    padding-right: 20px;
  }
}

/** extra desktops  */
@media screen and (min-width: 1980px) {
  .logo {
    top: 40px;
  }
}

/** laptops */
@media all and (min-width: 865px) and (max-width: 1230px) {
}

/** tablet */
@media all and (min-width: 540px) and (max-width: 896px) {
  .logo {
    top: 2px;
    width: 76px;
    height: 41px;
  }
  .logo--main {
    width: 130px;
    height: 71px;
    margin: 0 40px 0 0;
  }
  .page {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
  .info {
    position: absolute;
    top: 25px;
    left: 204px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #8b939e;
  }
}

/** phone */
@media all and (max-width: 540px) {
  .logo {
    display: none;
  }
  .logo--main {
    display: block;
    width: 108px;
    height: 59px;
    margin: 0 0 16px 0;
  }
  .page {
    padding: 0;
  }
}

/* tablet vertical */
@media all and (min-width: 540px) and (max-width: 896px) and (min-height: 800px) {
  .page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 307px;
  }
  .bottom {
    width: calc(100% - 340px);
    left: 50px;
    bottom: 344px !important;
  }
}
