.turn-phone {
  display: none;
}

.extra {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #8b939e;
}

.green {
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  color: #25b06d;
  display: block;
}

.aside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  gap: 16px;
  padding: 16px 0;
}

.aside__top {
  background: #f2f5f5;
  border-radius: 20px;
  flex: 1 1 100%;
  width: 100%;
}

.aside__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 80px);
  margin: auto;
  padding: 40px 0;
  border-bottom: 1px solid rgba(148, 154, 165, 0.4);
}

.aside__head > .h3 {
  font-size: var(--special-font-size);
  padding-right: 20px;
}

.aside__head > .h3 b.aside__head_desktop,
.aside__head > .h3 b.aside__head_mobile {
  font-size: 15px;
}
.aside__head > .h3 b.aside__head_mobile {
  padding-right: 20px;
  font-size: 12px;
}

.aside__head_desktop {
  display: block;
}

.aside__head_mobile {
  display: none;
}

.aside__main {
  padding: 16px 40px 0 40px;
  will-change: opacity;
  transition: opacity 0.3s linear;
}

.aside__main.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.aside__main__row_disabled {
  opacity: 0.4;
}

.aside__main__row {
  display: flex;
  align-items: center;
  height: 70px;
  animation: slideDown 0.3s ease-in-out;
}

.aside__main__row > label {
  margin-left: auto;
}

.aside__main__row > img {
  margin-right: 4px;
}

.aside__link {
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 16px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  background: var(--main-color);
  border-radius: 16px;
  flex: 0 0 76px;
}

.aside__link + .h3 {
  padding-top: 24px;
}

.aside__foot {
  align-self: start;
  flex-direction: column;
  width: calc(100% - 80px);
  margin: auto;
  padding: 40px 0;
  height: 100%;
  background: #f2f5f5;
  border-radius: 20px;
}

.toggler__wrap {
  position: relative;
  display: flex;
  width: 80px;
  height: 40px;
  padding: 4px;
  border-radius: 52.2314px;
  background: #d9e0ea;
  transition: background-color 0.3s ease-in-out;
}

.toggler {
  position: absolute;
  top: 4px;
  left: 4px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  transition: left 0.3s ease-in-out;
}

input[type="checkbox"]:checked + .toggler__wrap {
  background: #25b06d;
}
input[type="checkbox"]:checked + .toggler__wrap > .toggler {
  left: 44px;
}

.head {
  position: absolute;
  width: calc(100% - 400px);
  background: #fff;
}

.main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-right: auto;
  overflow: hidden;
  border-right: 20px solid #fff;
  padding-top: 20px;
}

.video__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 40px);
  aspect-ratio: 2/1.5;
  -webkit-aspect-ratio: 2/1.5;
  overflow: hidden;
}

.video {
  position: absolute;
  bottom: 0;
  width: auto !important;
  height: 100%;
  clip-path: inset(1px 1px);
  transition: opacity 0.5s ease-in-out;
  /* top: -40px;
  left: -80px; */
  /* object-fit: contain; */
}

.video:focus-visible {
  outline: none;
}

.video.unactive {
  opacity: 0;
}

.video.active {
  opacity: 1;
}

.back-btn {
  position: absolute;
  z-index: 10;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background: #f2f5f5;
  border-radius: 16px;
  text-decoration: none;
  transition: box-shadow 0.2s ease-out;
}

.back-btn span {
  margin-left: 12px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: var(--special-font-size);
  line-height: 22px;
  text-align: center;
  color: #646478;
}

.back-btn svg {
  width: 24px;
  height: 24px;
}

.back-btn:hover {
  box-shadow: 0 8px 16px rgba(122, 134, 143, 0.08);
}

.back-btn:hover span {
  color: #167ffb;
  transition: color 0.2s ease-out;
}

.back-btn:hover svg path {
  fill: #167ffb;
  transition: fill 0.2s ease-out;
}

.modal__trigger {
  cursor: pointer;
  position: absolute;
  z-index: 90;
  bottom: 16px;
  left: 16px;
  display: none;
  width: 32px;
  height: 32px;
}

.bottom {
  position: absolute;
  z-index: 90;
  bottom: 18px;
  left: 16px;
  width: calc(100% - 410px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.legend {
  flex: 0 0 460px;
}

.info_text {
  font-size: 10px;
  color: #a7a6ac;
  flex: 0 1 36%;
}

.legend__item {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 4px;
}

.legend__item_anim {
  background-color: #d2d3e4;
  flex: 0 0 46px;
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
  background-image: url("../../img/dashes-1.png");
  background-repeat: repeat-x;
  background-size: 100%;
  background-position: 7px center;
  animation: slideLeft infinite linear 1.9s;
}

.legend__item.m--round .legend__item_anim {
  background-image: url("../../img/dashes-2.png");
  background-position: 8px center;
  animation: slideLeft infinite linear 3.7s;
}

.legend__item.m--long .legend__item_anim {
  background-image: url("../../img/dashes-3.png");
  background-position: 3px center;
}

.legend__item_text {
  margin-left: 8px;
  color: #a7a6ac;
  background: #fff;
  padding: 0 8px;
  border-radius: 10px;
  font-size: 12px;
}

@keyframes slideLeft {
  0% {
    background-position-x: 7px;
  }
  100% {
    background-position-x: 30px;
  }
}

.aside__mobile-closer {
  display: none;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 2560px) {
  .h2 {
    font-size: 56px;
    line-height: 68px;
  }
  .h3 {
    font-size: 17px;
    line-height: 22px;
  }
  .extra {
    font-size: 18px;
    line-height: 22px;
  }
  .green {
    font-size: 40px;
    line-height: 49px;
  }
  .text {
    font-size: 18px;
    line-height: 22px;
    /* padding-right: 20px; */
  }

  .aside {
  }

  .aside--first-slide {
  }

  .aside__head {
    padding: 24px 0;
    width: calc(100% - 48px);
    margin: auto;
  }

  .aside__foot {
    padding: 24px;
    width: 100%;
    margin: auto;
    /*flex: 0 0 260px;*/
  }

  .aside__main {
    padding: 0 16px;
  }

  .toggler__wrap {
    width: 56px;
    height: 28px;
    padding: 3px;
  }
  .toggler {
    width: 22px;
    height: 22px;
    top: 3px;
    left: 3px;
  }
  input[type="checkbox"]:checked + .toggler__wrap > .toggler {
    left: 31px;
  }
  .line {
    width: 307px;
    margin: 0 24px;
  }
  .line--two {
    margin: auto 24px 24px 24px;
  }
}

@media screen and (max-width: 1979px) and (max-height: 630px) {
  .aside__head,
  .aside__foot {
    padding: 16px 0;
  }

  .aside__top {
    width: 100%;
  }

  .aside__foot {
    padding: 16px;
  }

  .aside__head .h3,
  .aside__foot .h3 {
    font-size: 16px;
    border: none;
  }

  .aside__foot .h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .aside__link {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .aside__link span {
    font-size: 14px;
  }

  .aside__foot div:nth-child(2) {
    margin-bottom: 8px !important;
  }

  .aside__foot .green {
    font-size: 32px;
    line-height: 40px;
  }

  .aside__main__row {
    height: 48px;
  }

  .aside__main__row img {
    width: 48px;
    height: 48px;
  }

  .aside__main__row .text {
    font-size: 16px;
    line-height: 18px;
  }
}

/** phone portrait  */
@media all and (max-width: 540px) {
  .back-btn {
    z-index: 200;
  }
  .turn-phone {
    position: fixed;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    background: #fff;
  }
  .turn-phone svg {
    width: 116px;
    height: 116px;
    margin-bottom: 16px;
  }
  .turn-phone span {
    max-width: 320px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .info,
  .main,
  .aside {
    display: none;
  }
  .modal {
    display: none !important;
  }
}

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal__content {
  position: relative;
  display: flex;
  width: 75%;
  max-width: 550px;
  padding: 12px 16px;
  background: #fff;
  border-radius: 8px;
}
.modal__content span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8b939e;
  padding-right: 30px;
}
.modal__content svg {
  width: 24px;
  height: 20px;
}
.modal__content svg path {
  fill: #2c3e50;
}

.modal.large .modal__content {
  border-radius: 20px;
  width: 564px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 10px);
  margin-top: 0;
  background: #e7edef;
  /* border: 8px solid #ffffff; */
  padding: 20px 32px;
  justify-content: space-between;
}

.modal .modal__closer {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 16px;
}
.modal.large .modal__closer {
  min-width: 20px;
  top: 20px;
  right: 24px;
}

.modal.large h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #000000;
  margin-bottom: 20px;
  padding-right: 30px;
}

.expense-items {
  padding-bottom: 12px;
  overflow-y: auto;
  height: calc(100% - 60px);
}

.expense-item {
  display: flex;
  align-items: center;
}

.expense-item + .expense-item {
  margin-top: 12px;
}

.expense-item__image {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 12px;
}

.expense-item__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
  margin-bottom: 5px;
}

span.expense-item__value {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #167ffb;
  margin-right: 4px;
  padding-right: 0;
}

@media screen and (max-width: 1200px) {
  .info_text {
    display: none;
  }
  .bottom {
    left: 64px;
  }
  .modal__trigger {
    display: block;
  }
  .legend {
    flex: 0 0 calc(100% + 20px);
  }
}

/** phone landscape  */
@media all and (max-width: 896px) {
  .bottom {
    width: calc(100% - 340px);
    left: 50px;
  }
  .legend__item_anim {
    transform: scale(0.7);
    transform-origin: right center;
  }
  .legend__item_text {
    font-size: 7px;
    margin-left: 0;
  }
  .video__wrapper {
    aspect-ratio: 2/1.3;
    -webkit-aspect-ratio: 2/1.3;
  }
  .page {
    flex-direction: row;
  }
  .main {
    height: calc(100vh - 32px);
    width: calc(100% - 275px);
    border: none;
  }
  .back-btn {
    padding: 16px;
    border-radius: 12px;
  }
  .back-btn > svg {
    width: 16px;
    height: 16px;
  }
  .back-btn > span {
    display: none;
  }
  .aside {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 16px;
    width: 255px;
    height: 100vh;
    margin: 0;
    background: transparent;
    gap: 0;
  }
  .aside__top {
    background: transparent;
    width: 100%;
    flex: 0 0 46px;
  }
  .aside__head {
    display: flex;
    width: calc(100% - 8px);
    margin: 0 0 0 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 12px;
    background: var(--main-color);
  }
  .aside__head > .h3 {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    padding-right: 0;
    width: 100%;
    justify-content: space-between;
  }
  .aside__head > .h3::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url("../../img/arrow-down.png") no-repeat center;
  }
  .aside__head_mobile {
    display: block;
  }
  .aside__head_desktop {
    display: none;
  }
  .aside__head > label {
    display: none;
  }
  .aside__main {
    display: none;
  }
  .aside__link {
    border-radius: 12px;
    padding: 0 10px 0 16px;
    margin: 0 8px;
    flex: 0 0 46px;
    width: calc(100% - 8px);
  }
  .aside__link span {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .aside--mobile-open .aside__link {
    display: none;
  }
  .aside__foot {
    flex: 1 1 100%;
    padding: 16px 12px 8px 12px;
    background: var(--aside-bg-color);
    border: 8px solid #fff;
    border-radius: 20px;
    width: calc(100% + 8px);
  }
  .aside__foot .h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border: none;
  }
  .aside__foot > div {
    display: flex;
    align-items: center;
  }
  .aside__foot .h2 {
    margin-right: 8px !important;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  .aside__foot .green {
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    display: block;
  }
  .aside__foot .extra {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
  }
  .modal__trigger {
    cursor: pointer;
    position: absolute;
    z-index: 90;
    bottom: 16px;
    left: 16px;
    display: block;
    width: 32px;
    height: 32px;
  }
  .info {
    display: none;
  }

  /* aside open */
  .aside--mobile-open {
    background: var(--aside-bg-color);
    width: 247px;
    height: calc(100% - 32px);
    margin: 16px 0 0 8px;
    border-radius: 20px;
    padding: 0;
    /* border-left: 8px solid #ffffff; */
  }
  .aside--mobile-open .aside__head {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    padding: 16px;
    border-radius: 20px 20px 0 0;
    width: 100%;
    margin: 0;
    padding-right: 30px;
    background: transparent;
  }
  .aside--mobile-open .aside__head:after {
    position: absolute;
    content: "";
    width: calc(100% - 32px);
    height: 1px;
    background: rgba(148, 154, 165, 0.4);
    bottom: 0;
  }
  .aside--mobile-open .aside__head .aside__mobile-closer {
    position: absolute;
    right: 12px;
    top: 12px;
    grid-area: closer;
    display: block;
    width: 14px;
    height: 14px;
  }
  .aside--mobile-open .aside__head .h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }
  .aside--mobile-open .aside__head .h3::after {
    display: none;
  }
  .aside--mobile-open .aside__head label {
    display: flex;
  }
  .aside--mobile-open .aside__head > label {
    margin-top: 12px;
  }
  .aside--mobile-open .aside__head .toggler__wrap {
    width: 48px;
    height: 24px;
  }
  input[type="checkbox"]:checked + .toggler__wrap > .toggler {
    left: 27px;
  }
  .aside--mobile-open .aside__head .toggler {
    width: 18px;
    height: 18px;
  }
  /*input[type="checkbox"]:checked + .toggler__wrap > .toggler {
    left: 27px;
  }*/

  .aside--mobile-open .aside__main {
    display: block;
    padding: 0 8px;
    background: var(--aside-bg-color);
  }
  .aside--mobile-open .aside__main .aside__main__row {
    height: 40px;
  }
  .aside--mobile-open .aside__main .aside__main__row .text {
    font-size: 11px;
    line-height: 14px;
    padding-right: 20px;
  }

  .aside--mobile-open .aside__foot {
    flex-direction: row;
    width: 266px;
    height: auto;
    padding-right: 30px;
    border: none;
    border-radius: 0 0 20px 20px;
    display: none;
  }
  .aside--mobile-open .aside__foot .h3 {
    display: none;
  }
  .aside--mobile-open input[type="checkbox"]:checked .aside__foot div {
    width: 100px;
    margin-right: auto;
  }
}

/* tablet vertical */
@media all and (min-width: 540px) and (max-width: 896px) and (min-height: 800px) {
  .back-btn {
    padding: 12px 16px;
    border-radius: 12px;
  }
  .back-btn > span {
    display: block;
  }
  .main {
    height: calc(100vh - 340px);
    width: 100%;
  }

  .modal__trigger {
    bottom: 340px;
  }

  .info {
    display: none;
    top: 4px;
    left: 168px;
  }

  .aside {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-radius: 24px;
    overflow: hidden;
    height: auto;
    top: auto;
    right: auto;
    padding: 0 16px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .aside__top {
    width: calc(50% - 8px);
    background: #f2f5f5;
    flex: 1 1 auto;
  }

  .aside__head > label,
  .aside__main {
    display: block;
  }

  .aside__foot .h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: none;
  }

  .aside__foot .h2 {
    font-weight: 700;
    font-size: 52px;
    line-height: 63px;
  }

  .aside__head {
    background: transparent;
    border-bottom: 1px solid rgba(148, 154, 165, 0.4);
    border-radius: 0;
    width: auto;
    padding: 20px;
  }

  .aside__head > .h3 {
    padding-right: 60px;
  }

  .aside__head > .h3 b.aside__head_desktop,
  .aside__head > .h3 b.aside__head_mobile {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .aside__head > .h3 b.aside__head_desktop {
    display: none;
  }

  .aside__head > .h3::after {
    display: none;
  }

  .aside__link {
    width: calc(50% - 8px);
    background: var(--main-color);
    padding: 7px 16px;
    margin: 0;
    flex: auto;
  }

  .aside__link + .h3 {
    border: none;
  }

  .logo {
    right: 17px;
    top: 0;
  }

  .aside__foot {
    flex: 0 0 221px;
    border: none;
    padding: 20px;
    height: 100%;
    margin: 0;
    background: #f2f5f5;
    border-radius: 20px;
    width: calc(50% - 8px);
  }

  .aside__link span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .video__wrapper {
    aspect-ratio: 2/1.3;
    -webkit-aspect-ratio: 2/1.3;
    width: 120%;
    height: 80% !important;
  }
}

@supports (-webkit-touch-callout: none) {
  html,
  body {
    overscroll-behavior-y: none;
  }

  body {
    overflow: hidden;
  }

  #root,
  .page,
  .main,
  .aside {
    /* height: -webkit-fill-available; */
    height: 80vh;
  }
  .main-page {
    overflow: auto;
    /* height: -webkit-fill-available; */
    /* padding: 0; */
  }
  .main-page__head {
    padding-top: 48px;
  }
  .main-page__main {
    padding-bottom: 20px;
  }
  @media all and (min-width: 540px) and (max-width: 896px) {
    .page {
      padding: 0;
    }

    .logo {
      top: 18px;
    }
  }
  @media (max-width: 896px) {
    .video__wrapper {
      width: 100%;
      height: 100%;
      margin-left: 0;
    }
  }
}

@media all and (min-width: 1201px) {
  .video__wrapper {
    max-height: calc(100vh - 70px);
    margin-top: -40px;
  }
  .info_text {
    flex: 0 1 100%;
    /* max-width: 350px;
    min-width: 350px; */
    width: 350px;
  }
  .legend {
    flex: 0 0 470px;
  }
}
